<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-overlay :show="isBusy">
        <b-card>
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form class="needs-validation" @submit.prevent="handleSubmit(itemFormSubmit)" novalidate>
              <h6 class="heading-small text-muted mb-4">Content</h6>

              <div class="">
                <b-row>
                  <!--<b-col lg="12">
                    <media-input
                      name="Image"
                      label="Image"
                      :required="true"
                      v-model="itemForm.media_id"
                    >
                    </media-input>
                  </b-col>-->

                  <b-col lg="12">
                    <media-input name="Image" label="Image" v-model="itemForm.media_id">
                    </media-input>
                  </b-col>

                  <b-col lg="6">
                    <base-input type="text" :name="'Name - English'" label="Name - English" :required="true"
                      placeholder="Name - English" v-model="itemForm.name.en">
                    </base-input>
                  </b-col>
                  <b-col lg="6">
                    <base-input type="text" :name="'Name - Arabic'" label="Name - Arabic" :required="true"
                      placeholder="Name - Arabic" v-model="itemForm.name.ar">
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <html-editor :name="'Description - English'" label="Description - English" :rows="'5'"
                      :required="true" placeholder="Description - English" :type="'html'"
                      v-model="itemForm.description.en">
                    </html-editor>
                  </b-col>

                  <b-col lg="6">
                    <html-editor :name="'Description - Arabic'" label="Description - Arabic" :rows="'5'" :required="true"
                      placeholder="Description - Arabic" :type="'textarea'" v-model="itemForm.description.ar">
                    </html-editor>
                  </b-col>

                  <b-col lg="6">
                    <base-input type="select" :required="true" label="Type" :name="'Type'" placeholder="Type"
                      v-model="itemForm.type" :options="types" :key-field="'value'" :value-field="'value'"
                      :text-field="'name'"></base-input>
                  </b-col>

                  <b-col lg="6">
                    <base-input type="select" :required="true" label="Resort" :name="'Resort'" placeholder="Resort"
                      v-model="itemForm.resort_id" :options="resorts" :key-field="'value'" :value-field="'value'"
                      :text-field="'name'"></base-input>
                  </b-col>

                  <b-col lg="6">
                    <base-input :name="'Sort Order'" label="Sort Order" :required="true" placeholder="Sort Order"
                      type="number" v-model="itemForm.sort_order">
                    </base-input>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group>
                      <label class="form-control-label">Tags</label>
                      <b-form-tags input-id="tags-basic" v-model="itemForm.tags"></b-form-tags>
                    </b-form-group>
                  </b-col>


                  <b-col lg="6">
                    <base-input type="select" :required="true" label="Duration" :name="'Duration'" placeholder="Duration"
                      v-model="itemForm.duration" :options="durations" :key-field="'value'" :value-field="'value'"
                      :text-field="'name'"></base-input>
                  </b-col>


                  <b-col lg="6">
                    <base-multi-select ref="selectedvideos" v-model="itemForm.training_videos" :options="mappedVideos"
                      :multiple="true" placeholder="Videos" label="Videos (multi-select)" track-by="name">
                    </base-multi-select>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label class="form-control-label">Visible</label>
                      <b-form-checkbox class="mb-3" v-model="itemForm.visible">
                        Hide from mobile app
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label class="form-control-label">Sync Names</label>
                      <b-form-tags placeholder="Add names for sync" input-id="syncNames"
                        v-model="itemForm.syncNames"></b-form-tags>
                    </b-form-group>
                  </b-col>
                </b-row>

              </div>

              <base-button type="primary" native-type="submit" class="my-4">
                {{ isUpdate() ? "Update Information" : "Create" }}
              </base-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import MediaInput from "@/components/Inputs/MediaInput";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import * as videosRepo from "@/api-repo/videos";
import * as treatmentsRepo from "@/api-repo/treatments";

import { handleError, successMessage } from "@/util/error-handler";
import BaseMultiSelect from "@/components/Inputs/BaseMultiSelect.vue";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    MediaInput,
    HtmlEditor,
    BaseMultiSelect,
  },
  props: ["type"],
  data() {
    return {
      isBusy: false,
      durations: [
        { name: "20 minutes", value: 20 },
        { name: "25 minutes", value: 25 },
        { name: "30 minutes", value: 30 },
        { name: "40 minutes", value: 40 },
        { name: "45 minutes", value: 45 },
        { name: "50 minutes", value: 50 },
        { name: "60 minutes", value: 60 },
        { name: "70 minutes", value: 70 },
        { name: "75 minutes", value: 75 },
        { name: "80 minutes", value: 80 },
        { name: "90 minutes", value: 90 },
      ],
      types: [
        { name: "MASSAGE THERAPIES", value: "MASSAGE THERAPIES" },
        { name: "BODY THERAPIES", value: "BODY THERAPIES" },
        { name: "FACIAL THERAPIES", value: "FACIAL THERAPIES" },
        { name: "HOLISTIC AND TRADITIONAL THERAPIES", value: "HOLISTIC AND TRADITIONAL THERAPIES" },
        { name: "ENERGY HEALING", value: "ENERGY HEALING" },
        { name: "MINDFULNESS", value: "MINDFULNESS" },
        { name: "RITUALS", value: "RITUALS" },
        { name: "PHYSIOTHERAPY", value: "PHYSIOTHERAPY" },
        { name: "WELLNESS CONSULTATIONS", value: "WELLNESS CONSULTATIONS" },
        { name: "FITNESS", value: "FITNESS" },
        { name: "AESTHETIC BEAUTY", value: "AESTHETIC BEAUTY" },
        { name: "CULINARY ACTIVITIES", value: "CULINARY ACTIVITIES" },
        { name: "SALON AND BARBER SERVICES", value: "SALON AND BARBER SERVICES" },
      ],
      resorts: [
        { name: "Serenity", value: "serenity" },
        { name: "Discovery", value: "discovery" },
      ],
      mappedVideos: [],
      itemForm: {
        media_id: null,
        name: { en: null, ar: null },
        description: { en: null, ar: null },
        resort_id: null,
        type: this.type,
        visible: null,
        sort_order: null,
        tags: null,
        training_videos: [],
      },
    };
  },

  created() {
    this.getInitData();
    if (this.isUpdate()) {
      this.getData();
    }
  },
  /*

media_id: 
    title: {en: , ar: }
    text: {en: , ar: }
    target_id: 
    target_type: 
    hidden: 
    tags: []
  */
  methods: {
    generateBreadCrumb() {
      return [{ label: "Treatments", route: "Treatments" }, { label: this.isUpdate() ? this.itemForm.name.en : "Add New Treatment" }];
    },
    getInitData: async function () {
      if (!this.isUpdate()) {
        this.getVideos();
      }
    },
    getVideos: async function () {
      let videoResults = await videosRepo.getList(this.userStore.state.token);
      this.mappedVideos = videoResults.data.data.map((e) => {
        return { name: e.name.en, value: e._id };
      });
    },

    getData: async function () {
      try {
        this.isBusy = true;
        await this.getVideos();
        let result = await treatmentsRepo.getById(
          this.$route.params.id,
          this.userStore.state.token
        );
        this.itemForm.media_id = result.data.data.media_id;
        this.itemForm.name = result.data.data.name;
        this.itemForm.description = result.data.data.description;
        this.itemForm.visible = result.data.data.visible || false;
        this.itemForm.type = result.data.data.type;
        this.itemForm.resort_id = result.data.data.resort_id;
        this.itemForm.sort_order = result.data.data.sort_order ? parseInt(result.data.data.sort_order) : null;
        this.itemForm.duration = result.data.data.duration;
        this.itemForm.tags = result.data.data.tags;
        this.itemForm.syncNames = result.data.data.syncNames;
        this.itemForm.training_videos = this.mapVideos(
          result.data.data.training_videos || []
        );
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },

    mapVideos(videoArrOfIds) {
      if (!videoArrOfIds) {
        return [];
      }
      return this.mappedVideos.filter((e) =>
        videoArrOfIds.find((id) => e.value === id)
      );
    },

    isUpdate: function () {
      return this.$route.params.id != null;
    },
    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        //this.itemForm.visible = this.itemForm.visible;
        const objectForSubmission = {
          ...this.itemForm,
          sort_order: this.itemForm.sort_order ? parseInt(this.itemForm.sort_order) : null,
          training_videos: this.itemForm.training_videos
            ? this.itemForm.training_videos.map((e) => e.value)
            : null,
        };
        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            await treatmentsRepo.create(
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item created successfully");
            this.$router.replace({ path: "/content/treatments/" });
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
            await treatmentsRepo.update(
              this.$route.params.id,
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },
  },
};
</script>
